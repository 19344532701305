<template>
  <div class="pb-2">
    <portal to="body-top">
      <h4 class="text-center font-weight-bolder">
        {{ $t('Inventory List —') }}
        <span v-if="warehouse.id">
          {{ warehouse.label }}
        </span>
        <span v-else>
          {{ $t('————') }}
        </span>
      </h4>
    </portal>

    <inventory-list-options />
    <l-table
      ref="lTableRef"
      :module-name="MODULE_NAME"
      :table-columns="tableColumnsCatalog"
      :filter-class="new InventoryProducts()"
      class="inventory-list-catalog-table"
      :is-mobile-view-custom="true"
      :is-search-input-one-line="true"
      :table-config-options="{
        endpoint: `${MODULE_NAME}/getStockProducts`,
      }"
    >
      <!--      <template #warning-icon-on-search>-->
      <!--        <b-button-->
      <!--          class="searchButton"-->
      <!--          style="background-color: #D9D9D9 !important; margin-left: 0 !important;"-->
      <!--          @click="warningClicked"-->
      <!--        >-->
      <!--          <feather-icon-->
      <!--            icon="LWarningGreyIcon"-->
      <!--            size="16"-->
      <!--          />-->
      <!--        </b-button>-->
      <!--      </template>-->
      <template #cell(actions)="{data}">
        <div>
          <Can
            :i="ACCESS_ABILITY_INVENTORY.action"
            :a="ACCESS_ABILITY_INVENTORY.subject"
          >
            <div class="d-flex align-items-center inventory-list-catalog-table__actions">
              <b-link
                :to="{ name: 'home-inventory-view' , params: { id: data.item.id }, query: { location: `${warehouse.id}` }}"
                class="text-body text-wrap"
              >
                <feather-icon
                  v-b-tooltip.noninteractive.hover
                  icon="LEyeIcon"
                  title="View"
                  size="24"
                  class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                />
              </b-link>
              <feather-icon
                v-b-tooltip.noninteractive.hover
                icon="LCalendarsIcon"
                size="24"
                title="Calendar"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                @click="gotoNewPage({ name: 'home-inventory-calendar', params: { id: data.item.id } }, $event)"
              />
              <feather-icon
                icon="LSignalIcon"
                size="24"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                @click="gotoNewPage({name: 'home-inventory-catalog-item-audit', params: {id: data.item.id}}, $event)"
              />
              <feather-icon
                v-if="data.item.inventory_item_current_warehouse_count > 0"
                v-b-tooltip.noninteractive.hover
                title="Create a permanent transfer"
                icon="LTransferCalendarIcon"
                size="24"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColorTwo"
                @click="gotoNewPage({name: 'transfer-create-by-inventory', params: {id: data.item.id}}, $event)"
              />
              <feather-icon
                v-if="data.item.inventory_item_current_warehouse_count <= 0"
                v-b-tooltip.noninteractive.hover
                title="Create a permanent transfer"
                icon="LTransferCalendarIcon"
                size="24"
                class="cursor-pointer border-dotted feather-trash-icon defaultIconColor"
              />
            </div>
          </Can>
        </div>
      </template>
      <template #cell(name)="{ data }">
        <span
          v-b-tooltip.noninteractive.hover
          class="name-cell"
          :title="data.item.id ? data.item.name : '---'"
        >
          {{ data.item.name }}
        </span>
      </template>

      <template #section="{ data: { item } }">
        <inventory-list-catalog-mobile-view
          :item="item"
          :columns="tableColumnsCatalog"
        />
      </template>
      <template #cell(inventory_item_current_warehouse_count)="{ data: {item} }">
        <div>
          {{ negativeResponseViewForCount(item.inventory_item_current_warehouse_count) }}
        </div>
      </template>
      <template #cell(inventory_item_other_warehouse_count)="{ data: {item} }">
        <div>
          {{ negativeResponseViewForCount(item.inventory_item_other_warehouse_count) }}
        </div>
      </template>
      <template #cell(inventory_item_count)="{ data: {item} }">
        <div>
          {{ negativeResponseViewForCount(item.inventory_item_count) }}
        </div>
      </template>
    </l-table>
  </div>
</template>

<script>
import LTable from '@/views/components/LTable/LTable.vue'
import { VBTooltip, BLink, BButton } from 'bootstrap-vue'
// eslint-disable-next-line import/no-cycle
import config from '@/views/main/inventory/inventoryConfig'
// Components
// eslint-disable-next-line import/no-cycle
import InventoryProducts from '@/views/components/filters/fields/InventoryProducts'
import InventoryListOptions from '../components/InventoryListOptions.vue'
import InventoryListCatalogMobileView from '../components/list/InventoryListCatalogMobileView.vue'

export default {
  name: 'InventoryListCatalog',
  components: {
    BButton,
    BLink,
    LTable,
    InventoryListOptions,
    InventoryListCatalogMobileView,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  data() {
    return {
      chosenFiles: null,
      batchDeleteArr: [],
    }
  },
  computed: {
    customers() {
      return this.$store.state[this.MODULE_NAME].customers
    },
    InventoryProducts() {
      return InventoryProducts
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
  },
  watch: {
    warehouse(val) {
      if (val) {
        this.switchTableCellOfStock(val.two_letter_code)
      } else {
        this.switchTableCellOfStock(this.warehouse.two_letter_code)
      }
    },
  },
  created() {
    this.$emit('updateMenu', 'home-inventory-list')
    this.$store.commit(`${this.MODULE_NAME}/GET`, {})
    this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_ID`, null)
    this.$store.commit(`${this.MODULE_NAME}/SET_INVENTORY_SERIALIZED_AND_NON_SERIALIZED_VAL`, null)
  },
  methods: {
    navigateToTransferOrder(item) {
      console.log(item)
      const { id } = item
      this.$router.push({ name: 'home-inventory-permanent-transfer-create', params: { id } })
    },
    navigateTo(id, e, name) {
      if (e.ctrlKey) {
        const route = this.$router.resolve({
          name,
          params: { inv_id: id },
        })
        window.open(route.href)
      } else {
        this.$router.push({
          name,
          params: { inv_id: id },
        })
      }
    },
    showModalToChoseCategory() {
      this.$bvModal.show('customer-list-file-upload_download')
    },
    hideModalToChoseCategory() {
      this.$bvModal.hide('customer-list-file-upload_download')
    },
    refetchData() {
      this.$refs.lTableRef.refetchData()
    },
    update(data) {
      this.$router.push({
        name: 'customer-update',
        params: { id: data.id },
      })
    },
    // eslint-disable-next-line consistent-return
    getData(data, dataName) {
      const { item: { default_contact } } = data
      if (dataName === 'fullname') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.first_name} ${default_contact.last_name}` : '—'
      } if (dataName === 'phone') {
        // eslint-disable-next-line camelcase
        return default_contact ? `${default_contact.phone_no}` : '—'
      }
    },
    renderSuggestion(suggestion) {
      const { item } = suggestion
      return item.cust_name_dba + (item.default_contact ? `: ${this.getData(suggestion, 'fullname')}` : '')
    },
    getSuggestionValue(suggestion) {
      const { item } = suggestion
      return item.cust_name_dba
    },
    getSearchProps() {
      return {
        'render-suggestion': this.renderSuggestion,
        'get-suggestion-value': this.getSuggestionValue,
      }
    },
    switchTableCellOfStock(warehouseTwoLetterCode) {
      const tableColumnsInventoryItemsAssetLabel = `Stock ${warehouseTwoLetterCode ?? '-'}`
      this.tableColumnsCatalog.forEach(tableColumn => {
        if (tableColumn.key === 'inventory_item_current_warehouse_count') {
          // eslint-disable-next-line no-param-reassign
          tableColumn.label = tableColumnsInventoryItemsAssetLabel
        }
      })
    },
    warningClicked() {
      console.log('Warning Clicked !')
    },
    negativeResponseViewForCount(count) {
      return count > 0 ? count : '—'
    },
  },
  setup() {
    const PRODUCT_MODULE_NAME = 'product'
    const MODULE_NAME = 'inventories'

    const { tableColumnsCatalog, ACCESS_ABILITY_INVENTORY } = config()
    return {
      tableColumnsCatalog,
      PRODUCT_MODULE_NAME,
      MODULE_NAME,
      ACCESS_ABILITY_INVENTORY,
    }
  },
}
</script>
<style lang="scss">
.name-cell {
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  overflow: hidden;
  line-height: 24px;
}

  .inventory-list-catalog-table {
    &__actions {
      gap: 12px;
    }

    @media only screen and (max-width: 1100px) {
      & table thead tr th:nth-child(2) {
        width: 30% !important;
      }

      & table thead tr th:nth-child(4),
      & table thead tr th:nth-child(5){
        width: 15% !important;
      }

      &__actions {
        gap: 8px;
      }
    }

  }
</style>
