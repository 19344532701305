<template>
  <div>
    <render-list :rows="3">
      <template #row-1-left>
        <div
          class="d-flex"
          style="gap: 10px"
        >
          <b>
            {{ getColByKey('sku') }}
          </b>
          <div>
            {{ item.sku }}
          </div>
        </div>
      </template>
      <template #row-1-right>
        <div class="d-flex justify-content-end">
          <inventory-list-catalog-actions :id="item.id" />
        </div>
      </template>
      <template #row-2-whole>
        <div
          class="d-flex"
          style="gap: 10px"
        >
          <b>
            {{ getColByKey('name') }}
          </b>
          <div>
            {{ item.name }}
          </div>
        </div>
      </template>
      <template #row-3-left>
        <div
          class="d-flex"
          style="gap: 10px"
        >
          <b>
            {{ getColByKey('2') }}
          </b>
          <div>
            {{ item.stock ? item.stock : "—" }}
          </div>
        </div>
      </template>
      <template #row-3-right>
        <div
          class="d-flex"
          style="gap: 10px"
        >
          <b>
            {{ getColByKey('1') }}
          </b>
          <div>
            {{ item.otherLock ? item.otherLock : "—" }}
          </div>
        </div>
      </template>
    </render-list>
  </div>
</template>

<script>
import InventoryListCatalogActions from './InventoryListCatalogActions.vue'

export default {
  name: 'InventoryListCatalogMobileView',
  components: {
    InventoryListCatalogActions,
  },
  props: {
    item: {
      type: Object,
      required: true,
    },
    columns: {
      type: Array,
      required: true,
    },
  },
  methods: {
    getColByKey(keyName) {
      return this.columns.find(col => col.key === keyName).label ?? 'No Label'
    },
  },
}
</script>
