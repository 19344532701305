<template>
  <div>
    <Can
      :i="ACCESS_ABILITY_INVENTORY.action"
      :a="ACCESS_ABILITY_INVENTORY.subject"
    >
      <div class="d-flex align-items-center inventory-list-catalog-table__actions">
        <b-link
          :to="{ name: 'home-inventory-view' , params: { id }, query: { location: `${warehouse.id}` }}"
          class="text-body text-wrap"
        >
          <feather-icon
            icon="LEyeIcon"
            size="24"
            :class="iconClassName"
          />
        </b-link>
        <feather-icon
          icon="LCalendarsIcon"
          size="24"
          :class="iconClassName"
          @click="gotoNewPage({ name: 'home-inventory-calendar', params: { id } }, $event)"
        />
        <feather-icon
          icon="LSignalIcon"
          size="24"
          :class="iconClassName"
        />
      </div>
    </Can>
  </div>
</template>

<script>
import config from '@/views/main/inventory/inventoryConfig'
import { BLink } from 'bootstrap-vue'

export default {
  name: 'InventoryListCatalogActions',
  components: {
    BLink,
  },
  props: {
    id: {
      type: [String, Number],
      required: true,
    },
  },
  computed: {
    appWindowWidth() {
      return this.$store.state.app.windowWidth
    },
    mobileWindowWidth() {
      return this.$store.state.app.mobileWindowWidth
    },
    appWidth() {
      return this.appWindowWidth < this.mobileWindowWidth
    },
    warehouse() {
      return this.$store.state.app.warehouse
    },
    iconClassName() {
      return `cursor-pointer border-dotted feather-trash-icon defaultIconColor ${this.appWidth ? 'btn-custom-style' : ''}`
    },
  },
  setup() {
    const { ACCESS_ABILITY_INVENTORY } = config()
    return {
      ACCESS_ABILITY_INVENTORY,
    }
  },
}
</script>
<style>
.btn-custom-style {
  min-width: 36px;
  min-height: 36px;
}
</style>
