import { ref } from '@vue/composition-api'
import Filter from './Filter'

export default class InventoryProducts extends Filter {
    filterComponent = import('@/views/components/filters/InventoryProductsFilterComponent.vue')

    static MODULE_NAME = 'inventories'

    static NAME = 'name'

    static TAGS = 'tags'

    static MODEL = 'model'

    static BRAND = 'brand'

    static PICK_LOCATION = 'location_id'

    static CONDITION = 'condition_id'

    static PRODUCT_RULES = 'rules'

    static CAN_BE_SOLD = 'can_be_sold'

    static WARNINGS = 'warnings'

    static CONFLICTS = 'conflicts'

    static ITEMS_WITH_NO_ASSETS = 'items_with_no_assets'

    fields = {
      [InventoryProducts.NAME]: {
        label: 'Name',
        placeholder: 'Type...',

      },
      [InventoryProducts.TAGS]: {
        label: 'Tags',
        store: 'tagsList',
        options: {
          multiple: true,
          label: 'label',
        },
        mapping(value) {
          if (Array.isArray(value)) return value.map(item => (item.id ? item.id : item))
          return []
        },
        selectedLabel(value) {
          if (Array.isArray(value)) {
            return value.map(item => item.label).join(', ')
          }
          return value
        },
        placeholder: 'Type...',
      },
      [InventoryProducts.MODEL]: {
        label: 'Model',
        placeholder: 'Type...',

      },
      [InventoryProducts.PICK_LOCATION]: {
        label: 'Pick Location',
      },
      [InventoryProducts.CONDITION]: {
        label: 'Condition',
        store: 'assetConditionList',
      },
      [InventoryProducts.BRAND]: {
        label: 'Brand',
        store: 'brandsList',
      },
      [InventoryProducts.PRODUCT_RULES]: {
        [InventoryProducts.CAN_BE_SOLD]: {
          label: 'Allow to sell',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryProducts.ITEMS_WITH_NO_ASSETS]: {
          label: 'Items with no assets',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryProducts.WARNINGS]: {
          label: 'Warnings',
          selectedValue: this.selectedOnlyLabel,
        },
        [InventoryProducts.CONFLICTS]: {
          label: 'Conflicts',
          selectedValue: this.selectedOnlyLabel,
        },
      },
    }

    selectedFilters = ref()

    // eslint-disable-next-line class-methods-use-this
    getResponseFilter(response) {
      return response.data.meta.filters ?? {}
    }

    selectedOnlyLabel(label, value) {
      if (value) return super.selectedOnlyLabel(label)
      return null
    }
}
